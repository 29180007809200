/* =============================================================
 * import
 * ========================================================== */
import 'intersection-observer';
// import {locoScroll} from "./parallax";


/* =============================================================
 * function
 * ========================================================== */
export function floating() {
  // floatingHeader();
  scrollContentsObserver();
}

/*
function floatingHeader() {

  // intersection-observerAPIだと、
  // ページ遷移時に要素の重なりを検知してしまい、
  // 誤作動が起きるため、以下の書き方。
  const header = document.querySelector('#header');
  const hum = document.querySelector('#hum');

  locoScroll.on("scroll", () => {
    const positionTop = locoScroll.scroll.instance.scroll.y;
    if (positionTop > 130) {
      header.classList.add('scrolled');
      hum.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
      hum.classList.remove('scrolled');
    }
  });

}
*/


function scrollContentsObserver() {

  const target = document.querySelectorAll('.js-txt-up, ._l, .clip_figure, .clip');
  const targetArray = Array.prototype.slice.call(target);

  // オプション設定
  const options = {
    root: null,
    rootMargin: '0px 0px',
    threshold: 0,
  };

  const observer = new IntersectionObserver(callback, options);
  targetArray.forEach((tgt) => {
    observer.observe(tgt);
  });

  function callback(entries) {
    entries.forEach(function(entry, i) {
      const target = entry.target;
      if (entry.isIntersecting && !target.classList.contains('is-active')) {
        const delay = i * 100;
        setTimeout(function(){
          target.classList.add('is-active');
        },delay);
      }
    });
  };
}


