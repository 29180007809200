/* =============================================================
 * import
 * ========================================================== */
import {mediaQuery} from './common';
import {eventObserver} from "./eventObserver";


/* =============================================================
 * globalNavi
 * グローバルナビに関する実行関数
 * ========================================================== */

export function globalNavi() {
  const windowWidth = window.innerWidth;
  const listElements = document.querySelectorAll('.header_gnav_list_item_link');
  const hum = document.getElementById('hum');
  const listeners = [];
  if (mediaQuery(windowWidth) === 'sp') {
    /* ----- SP ----- */

    if (hum) {
      // SP HumbergerMneu
      hum.addEventListener('click', humberger);
      listeners.push([hum, 'click', humberger]);

      listElements.forEach((elem) => {
        if (elem.parentNode.classList.contains('gnav_list_parent') === false) {
          elem.addEventListener('click', humberger, false);
          listeners.push([elem, 'click', humberger]);
        }
      });
    }

    eventObserver.observeEvents( listeners );
  }
}


/* =============================================================
 * humberger
 * ハンバーガーメニューの制御
 * ========================================================== */

export function humberger() {

  const html = document.documentElement;
  const hum = document.getElementById('hum');
  const header = document.getElementById('header');
  const gnav = document.getElementById('gnav');
  const filter = document.getElementById('filter');
  const logo = document.getElementById('header_logo');
  const logo2 = document.getElementById('header_gnav_logo');
  const listeners = [];

  if (hum) {

    if (hum.classList.contains('close')) {
      // class
      hum.classList.remove('close');
      header.classList.remove('close');
      gnav.classList.remove('close');
      filter.classList.remove('close');
    }

    if (hum.classList.contains('open')) {
      // class
      hum.classList.add('close');
      header.classList.add('close');
      gnav.classList.add('close');
      filter.classList.add('close');
      hum.classList.remove('open');
      header.classList.remove('open');
      gnav.classList.remove('open');
      filter.classList.remove('open');
      html.classList.remove('is-fixed');
      // eventListener
      logo.removeEventListener('click', humberger);
      logo2.removeEventListener('click', humberger);
    } else {
      // class
      hum.classList.add('open');
      header.classList.add('open');
      gnav.classList.add('open');
      filter.classList.add('open');
      html.classList.add('is-fixed');
      // eventListener
      logo.addEventListener('click', humberger);
      logo2.addEventListener('click', humberger);
      listeners.push([logo, 'click', humberger]);
      listeners.push([logo2, 'click', humberger]);
    }

  }

}


/* =============================================================
 * checkParentDirectory
 * 現在のディレクトリ名からサブページの有無を判定
 * ========================================================== */
export function checkParentDirectory(path) {
  let reg;
  let result;
  let hasChildDir = false;

  // 表示中のページ判定
  const childPathArray = ['/service/', '/works/', '/company/', '/about/', '/media/', '/contact/'];
  childPathArray.forEach(dir => {
    reg = new RegExp(dir);
    result = path.match(reg);
    if (result != null) {
      hasChildDir = true;
      return;
    }
  });
  return hasChildDir;
}

